<template>
  <section id="hero">
    <v-container class="fill-height px-4 py-12">
      <v-responsive class="d-flex align-center mx-auto" max-width="700" width="100%">
        <v-container class="fill-height px-2 py-2 align-center">
          <v-img :max-width="60" :src="require('@/assets/app_button.png')" class="mr-4" />

          <base-heading class="mt-4" size="text-h2" title="Gideon Bible App" weight="medium" />
        </v-container>

        <base-body>
          Read or listen to the Bible in over 1000 languages
        </base-body>

        <div :class="
          $vuetify.breakpoint.smAndDown
            ? 'flex-row justify-start'
            : 'align-center'
        " class="d-flex flex-no-wrap align-center">
          <a @click="goToAppStore">
            <v-img :max-width="$vuetify.breakpoint.smAndDown ? 120 : 166"
              :src="require('@/assets/icon_app_store.png')" />
          </a>

          <span class="font-weight-bold ml-4 mr-4 ">or</span>
          <a @click="goToGooglePlay">
            <v-img :max-width="$vuetify.breakpoint.smAndDown ? 120 : 166"
              :src="require('@/assets/icon_google_play.png')" />
          </a>
        </div>
      </v-responsive>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "AppFooter",
  provide: {
    theme: { isDark: false }
  },
  methods: {
    goToAppStore() {
      this.$gtag.event("gideon-bible-app-store", {
        event_category: "external_link",
        event_label: "gideon-bible-app-store",
        value: "https://apple.co/3GwpQ7r"
      });
      location.href = "https://apple.co/3GwpQ7r";
    },
    goToGooglePlay() {
      this.$gtag.event("gideon-bible-google-play", {
        event_category: "external_link",
        event_label: "gideon-bible-google-play",
        value: "https://bit.ly/3pICAB7"
      });
      location.href = "https://bit.ly/3pICAB7";
    }
  },
};
</script>
